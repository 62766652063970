define("shop/tailwind/config/text-sizes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  |-----------------------------------------------------------------------------
  | Text sizes                         https://tailwindcss.com/docs/text-sizing
  |-----------------------------------------------------------------------------
  |
  | Here is where you define your text sizes. Name these in whatever way
  | makes the most sense to you. We use size names by default, but
  | you're welcome to use a numeric scale or even something else
  | entirely.
  |
  | By default Tailwind uses the "rem" unit type for most measurements.
  | This allows you to set a root font size which all other sizes are
  | then based on. That said, you are free to use whatever units you
  | prefer, be it rems, ems, pixels or other.
  |
  | Class name: .text-{size}
  |
  */
  var _default = {
    xxs: '.5625rem',
    // 9px
    xs: '.6875rem',
    // 11px
    sm: '.75rem',
    // 12px
    base: '.875rem',
    // 14px
    lg: '1rem',
    // 16px
    xl: '1.25rem',
    // 20px
    '2xl': '1.5rem',
    // 24px
    '3xl': '1.875rem',
    // 30px
    '4xl': '2.25rem',
    // 36px
    '5xl': '2.625rem',
    // 42px
    '6xl': '3.375rem',
    // 54px
    '7xl': '4.25rem',
    // 68px
    '8xl': '5.375rem' // 86px

  };
  _exports.default = _default;
});