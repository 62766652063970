define("shop/templates/components/checkout-header/step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qSg7Qam+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"flex-1 block text-center\",[28,\"concat\",[\"checkout.\",[24,[\"step\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"pt-2 pb-4 \",[28,\"if\",[[24,[\"isActive\"]],\"text-black\",\"text-grey-dark\"],null]]]],[8],[0,\"\\n    \"],[1,[28,\"t\",[[28,\"concat\",[\"checkout.step.\",[28,\"if\",[[28,\"and\",[[28,\"eq\",[[24,[\"step\"]],\"payment\"],null],[24,[\"session\",\"order\",\"isHto\"]]],null],\"confirmation\",[24,[\"step\"]]],null]],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/checkout-header/step.hbs"
    }
  });

  _exports.default = _default;
});