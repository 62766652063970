define("shop/templates/components/order-overview/gift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TlwF7e8p",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mb-6 lg:mb-12\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"text-xs uppercase tracking-wide mb-4 pb-4 border-b border-grey-lighter\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"order.overview.label.giftCard\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mb-2\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[1,[28,\"t\",[\"order.overview.label.recipient\"],null],false],[0,\":\"],[9],[0,\"\\n    \"],[1,[24,[\"item\",\"giftRecipient\"]],false],[0,\", \"],[1,[24,[\"item\",\"giftRecipientEmail\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mb-2\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[1,[28,\"t\",[\"order.overview.label.deliveryDate\"],null],false],[0,\":\"],[9],[0,\"\\n    \"],[1,[28,\"format-date\",[[24,[\"item\",\"giftDeliveryDate\"]]],[[\"format\"],[\"date\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mb-2\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[1,[28,\"t\",[\"order.overview.label.sender\"],null],false],[0,\":\"],[9],[0,\"\\n    \"],[1,[24,[\"item\",\"giftSender\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"item\",\"giftNotes\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"strong\",true],[8],[1,[28,\"t\",[\"order.overview.label.notes\"],null],false],[0,\":\"],[9],[0,\"\\n      \"],[1,[24,[\"item\",\"giftNotes\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/order-overview/gift.hbs"
    }
  });

  _exports.default = _default;
});