define("shop/components/progress-bar", ["exports", "nprogress"], function (_exports, _nprogress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.router.on('routeWillChange', function (transition) {
        if (transition && !transition.queryParamsOnly) {
          _nprogress.default.start({
            showSpinner: false
          });
        }
      });
      this.router.on('routeDidChange', function () {
        _nprogress.default.done(false);
      });
    }
  });

  _exports.default = _default;
});