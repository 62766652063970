define("shop/routes/payment/failed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    redirect: function redirect() {
      var locale = this.session.locale;
      this.transitionTo('checkout.payment', locale);
    }
  });

  _exports.default = _default;
});