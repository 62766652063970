define("shop/controllers/shop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['menu', 'cart', 'help'],
    session: Ember.inject.service(),
    media: Ember.inject.service(),
    menu: false,
    cart: false,
    help: '',
    layoutIsVisible: true,
    alertIsVisible: Ember.computed.not('session.shop.hasAutoDiscount'),
    countdownIsVisible: Ember.computed.bool('session.shop.hasAutoDiscount'),
    menuIsVisible: Ember.computed.bool('menu'),
    cartIsVisible: Ember.computed.bool('cart'),
    helpIsVisible: Ember.computed.bool('help'),
    actions: {
      navigateBack: function navigateBack() {
        if (window.history.length == 1) {
          this.transitionToRoute({
            queryParams: {
              menu: false,
              cart: false,
              help: ''
            }
          });
        } else {
          window.history.back();
        }
      }
    }
  });

  _exports.default = _default;
});