define("shop/templates/components/main-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3D1mkNlC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"bg-black text-white flex flex-col lg:flex-row\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"block lg:w-1/3 bg-im-14 bg-cover bg-center text-center py-32 lg:py-24\",\"stores\"]],{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"inline-block text-white font-medium tracking-tight border-b-4 border-white text-6xl pb-1\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"footer.stores\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"block lg:w-3/4 p-8 lg:p-16\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-8 mb-12 lg:mb-32\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"lg:w-1/2 px-8 lg:px-12\"],[8],[0,\"\\n        \"],[1,[22,\"main-footer/newsletter\"],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"hidden lg:block lg:w-1/2 px-8 lg:px-12\"],[8],[0,\"\\n        \"],[1,[28,\"main-footer/sitemap\",null,[[\"session\"],[[24,[\"session\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[28,\"main-footer/links\",null,[[\"session\"],[[24,[\"session\"]]]]],false],[0,\"\\n    \"],[1,[22,\"main-footer/legal\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/main-footer.hbs"
    }
  });

  _exports.default = _default;
});