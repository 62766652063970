define("shop/templates/eye-test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "regtXLFU",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"eyeTest.pageTitle\"],null]],null],false],[0,\"\\n\"],[1,[28,\"eye-test/locations\",null,[[\"id\",\"session\",\"locations\",\"appointment\",\"select\"],[\"locations\",[24,[\"session\"]],[24,[\"locations\"]],[24,[\"appointment\"]],[28,\"action\",[[23,0,[]],\"selectLocation\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"appointment\",\"hasLocation\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"eye-test/slots\",null,[[\"id\",\"session\",\"appointment\",\"location\",\"select\"],[\"slots\",[24,[\"session\"]],[24,[\"appointment\"]],[24,[\"appointment\",\"location\"]],[28,\"action\",[[23,0,[]],\"selectSlot\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"appointment\",\"startsAt\"]]],null,{\"statements\":[[4,\"with\",[[28,\"changeset\",[[24,[\"appointment\"]],[24,[\"AppointmentValidator\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"eye-test/customer\",null,[[\"id\",\"session\",\"appointment\",\"changeset\",\"isSaving\",\"save\"],[\"customer\",[24,[\"session\"]],[24,[\"appointment\"]],[23,1,[]],[24,[\"isSaving\"]],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/eye-test.hbs"
    }
  });

  _exports.default = _default;
});