define("shop/templates/components/product-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ClbRbLc2",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"product-header/title\",null,[[\"session\",\"product\"],[[24,[\"session\"]],[24,[\"product\"]]]]],false],[0,\"\\n\"],[1,[28,\"product-header/variants\",null,[[\"product\",\"products\"],[[24,[\"product\"]],[24,[\"products\"]]]]],false],[0,\"\\n\"],[1,[28,\"product-header/price\",null,[[\"session\",\"product\"],[[24,[\"session\"]],[24,[\"product\"]]]]],false],[0,\"\\n\"],[4,\"unless\",[[24,[\"product\",\"isOutOfStock\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"product-header/actions\",null,[[\"session\",\"product\",\"showSurcharges\",\"showCart\"],[[24,[\"session\"]],[24,[\"product\"]],[28,\"action\",[[23,0,[]],[24,[\"showSurcharges\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"showCart\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"product-header/tabs\",null,[[\"session\",\"product\"],[[24,[\"session\"]],[24,[\"product\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/product-header.hbs"
    }
  });

  _exports.default = _default;
});