define("shop/models/prescription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    odSphere: attr('number', {
      defaultValue: 0
    }),
    odCylinder: attr('number', {
      defaultValue: 0
    }),
    odAxis: attr('number', {
      defaultValue: 0
    }),
    odAdd: attr('number', {
      defaultValue: 0
    }),
    odPupilDistance: attr('number', {
      defaultValue: 32
    }),
    osSphere: attr('number', {
      defaultValue: 0
    }),
    osCylinder: attr('number', {
      defaultValue: 0
    }),
    osAxis: attr('number', {
      defaultValue: 0
    }),
    osAdd: attr('number', {
      defaultValue: 0
    }),
    osPupilDistance: attr('number', {
      defaultValue: 32
    }),
    url: attr('string'),
    imageUrl: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    pupilDistance: Ember.computed('odPupilDistance', 'osPupilDistance', {
      get: function get() {
        return this.odPupilDistance + this.osPupilDistance;
      },
      set: function set(key, value) {
        this.setProperties({
          odPupilDistance: value / 2,
          osPupilDistance: value / 2
        });
        return value;
      }
    })
  });

  _exports.default = _default;
});