define("shop/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return attr.underscore();
    },
    keyForRelationship: function keyForRelationship(relationship) {
      return relationship.underscore();
    },
    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
      if (Ember.isArray(payload.data)) {
        payload.data = payload.data[0];
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });

  _exports.default = _default;
});