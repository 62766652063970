define("shop/routes/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.query('review', {
        sort: '-paid_at'
      });
    }
  });

  _exports.default = _default;
});