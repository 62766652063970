define("shop/controllers/stores/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    locations: Ember.computed.filterBy('model', 'isPublic', true),
    locationSorting: Ember.A(['county:asc', 'title:desc']),
    sortedLocations: Ember.computed.sort('locations', 'locationSorting')
  });

  _exports.default = _default;
});