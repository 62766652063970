define("shop/components/product-pricing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    retailPrice: Ember.computed('product.exchangedPrice', function () {
      return Math.round(this.product.exchangedPrice * 0.02) * 100;
    })
  });

  _exports.default = _default;
});