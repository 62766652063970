define("shop/templates/components/cookies-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+yxk1+ew",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex lg:max-w-xs mx-auto p-1 bg-white rounded-full shadow-lg\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"block px-4 py-2\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"text-blue font-medium\",\"page\",\"privacy-policy\"]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"notification.cookies.title\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"bg-blue rounded-full px-4 py-2 text-white font-medium\"],[3,\"action\",[[23,0,[]],\"accept\"]],[8],[1,[28,\"t\",[\"notification.cookies.btn\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/cookies-notification.hbs"
    }
  });

  _exports.default = _default;
});