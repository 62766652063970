define("shop/templates/components/shop/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D4n42T/j",
    "block": "{\"symbols\":[\"service\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"bg-black py-12 lg:py-16 lg:px-12\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"max-w-4xl mx-auto\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"relative w-full lg:w-auto flex snap-x overflow-x-auto scrollbar-hide lg:-mx-3\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"array\",[\"stores\",\"eyeTest\",\"homeTryOn\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"w-3/4 lg:w-1/3 pl-4 lg:px-3 flex-no-shrink\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"block relative\",[28,\"dasherize\",[[23,1,[]]],null]]],{\"statements\":[[0,\"            \"],[7,\"img\",true],[11,\"src\",[28,\"if\",[[28,\"eq\",[[23,1,[]],\"homeTryOn\"],null],\"/images/1.jpg\",[28,\"if\",[[28,\"eq\",[[23,1,[]],\"eyeTest\"],null],\"/images/15.jpg\",\"/images/16.jpg\"],null]],null]],[10,\"class\",\"block w-full\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"absolute pin-b pin-l pin-r pb-8 lg:pb-12 flex justify-center text-xl tracking-tight font-medium\"],[8],[0,\"\\n              \"],[7,\"div\",true],[10,\"class\",\"border-b-2 border-white text-white pb-2\"],[8],[0,\"\\n                \"],[1,[28,\"t\",[[28,\"concat\",[\"index.services.\",[23,1,[]]],null]],null],false],[0,\"\\n              \"],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/shop/services.hbs"
    }
  });

  _exports.default = _default;
});