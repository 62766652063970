define("shop/templates/components/product-pricing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qH7HDN5D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"max-w-3xl mx-auto\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex items-end text-center font-medium -mx-2 mb-8\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"block w-1/2 px-2\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"py-2 text-xl font-medium text-grey-light\"],[8],[0,\"\\n        \"],[1,[28,\"format-number\",[[24,[\"retailPrice\"]]],[[\"format\"],[[24,[\"session\",\"shop\",\"currency\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"bg-grey-lighter px-6 py-3 h-32 mb-1\"],[8],[1,[28,\"t\",[\"product.pricing.retail\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"bg-grey-light px-6 py-3 text-white\"],[8],[1,[28,\"t\",[\"product.pricing.costs\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"block w-1/2 px-2\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"py-2 text-xl font-medium text-grey-light\"],[8],[0,\"\\n        \"],[1,[28,\"format-number\",[[24,[\"product\",\"exchangedPrice\"]]],[[\"format\"],[[24,[\"session\",\"shop\",\"currency\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"bg-grey-lighter px-6 py-3 mb-1\"],[8],[1,[28,\"t\",[\"product.pricing.brand\"],null],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"bg-grey-light px-6 py-3 text-white\"],[8],[1,[28,\"t\",[\"product.pricing.costs\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"t\",[\"product.pricing.description\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/product-pricing.hbs"
    }
  });

  _exports.default = _default;
});