define("shop/templates/components/ui-date-picker/day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "irNzEaAR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"role\",\"button\"],[12,\"date-test-ui-date-picker-day\",[22,\"title\"]],[12,\"class\",[29,[\"text-center py-2 lg:py-3 text-lg rounded-sm font-medium transition\\n  \",[28,\"if\",[[28,\"and\",[[24,[\"isSelectable\"]],[28,\"not\",[[24,[\"isSelected\"]]],null]],null],\"cursor-pointer hover:bg-grey-light\",\"text-grey cursor-auto\"],null],\"\\n  \",[28,\"unless\",[[24,[\"isInMonth\"]],\"opacity-50\"],null],\"\\n  \",[28,\"if\",[[24,[\"isSelected\"]],\"bg-blue text-white\"],null],\"\\n  \",[28,\"if\",[[24,[\"isToday\"]],\"bg-grey-lighter\"],null]]]],[3,\"action\",[[23,0,[]],\"select\"]],[8],[0,\"\\n  \"],[1,[22,\"title\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/ui-date-picker/day.hbs"
    }
  });

  _exports.default = _default;
});