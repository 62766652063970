define("shop/models/customer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    phone: attr('string'),
    locale: attr('string'),
    meta: attr('raw', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    shippingAddresses: hasMany('address'),
    billingAddresses: hasMany('address'),
    prescriptions: hasMany('prescription'),
    orders: hasMany('order'),
    appointments: hasMany('appointment'),
    prescriptionsSorting: Ember.A(['createdAt:desc']),
    addressesSorting: Ember.A(['createdAt:desc']),
    sortedShippingAddresses: Ember.computed.sort('shippingAddresses', 'addressesSorting'),
    sortedBillingAddresses: Ember.computed.sort('shippingAddresses', 'addressesSorting'),
    sortedPrescriptions: Ember.computed.sort('prescriptions', 'prescriptionsSorting'),
    shippingAddress: Ember.computed.readOnly('sortedShippingAddresses.firstObject'),
    billingAddress: Ember.computed.readOnly('sortedBillingAddresses.firstObject'),
    prescription: Ember.computed.readOnly('sortedPrescriptions.firstObject'),
    paidOrders: Ember.computed.filterBy('orders', 'isPaid'),
    openOrders: Ember.computed.filterBy('orders', 'isPaid', false),
    hasPrescription: Ember.computed.bool('prescription.id'),
    hasShippingAddress: Ember.computed.bool('shippingAddress.id'),
    hasBillingAddress: Ember.computed.bool('billingAddress.id'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return "".concat(this.firstName, " ").concat(this.lastName);
    })
  });

  _exports.default = _default;
});