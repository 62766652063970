define("shop/components/ui-date-picker", ["exports", "dayjs"], function (_exports, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('active', this.value || (0, _dayjs.default)().toDate());
    },
    days: Ember.computed('active', function () {
      var days = [];
      var date = (0, _dayjs.default)(this.active);
      var start = date.startOf('month').startOf('week');
      var end = date.endOf('month').endOf('week');

      while (!start.isSame(end.add(1, 'day'), 'day')) {
        days.push(start.toDate());
        start = start.add(1, 'day');
      }

      return days;
    }),
    actions: {
      showNextMonth: function showNextMonth() {
        this.set('active', (0, _dayjs.default)(this.active).add(1, 'month').toDate());
      },
      showPreviousMonth: function showPreviousMonth() {
        this.set('active', (0, _dayjs.default)(this.active).subtract(1, 'month').toDate());
      }
    }
  });

  _exports.default = _default;
});