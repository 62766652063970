define("shop/routes/account", ["exports", "shop/mixins/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticated.default, {});

  _exports.default = _default;
});