define("shop/models/appointment", ["exports", "ember-data", "dayjs"], function (_exports, _emberData, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    startsAt: attr('date'),
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    phone: attr('string'),
    locale: attr('string'),
    utmSource: attr('string'),
    utmMedium: attr('string'),
    utmCampaign: attr('string'),
    utmTerm: attr('string'),
    utmContent: attr('string'),
    shop: belongsTo('shop'),
    location: belongsTo('location'),
    customer: belongsTo('customer'),
    prescription: belongsTo('prescription'),
    hasShop: Ember.computed.bool('shop.id'),
    hasLocation: Ember.computed.bool('location.id'),
    hasCustomer: Ember.computed.bool('customer.id'),
    startTime: Ember.computed('startsAt', function () {
      return this.startsAt && (0, _dayjs.default)(this.startsAt).format('HH:mm');
    })
  });

  _exports.default = _default;
});