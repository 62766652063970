define("shop/templates/components/product-header/tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qV1Iq+pd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex text-center mb-6 font-medium\"],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"block w-1/2 pb-3 border-b\\n    border-grey-lighter \",[28,\"if\",[[28,\"eq\",[[24,[\"activeTab\"]],\"benefits\"],null],\"border-black\"],null]]]],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"activeTab\"]]],null],\"benefits\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"product.benefits.title\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"block w-1/2 pb-3 border-b\\n    border-grey-lighter \",[28,\"if\",[[28,\"eq\",[[24,[\"activeTab\"]],\"specs\"],null],\"border-black\"],null]]]],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"activeTab\"]]],null],\"specs\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"product.specs.title\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"component\",[[28,\"concat\",[\"product-header/\",[24,[\"activeTab\"]]],null]],[[\"session\",\"product\"],[[24,[\"session\"]],[24,[\"product\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"session\",\"shop\",\"hasPublicDiscount\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flex items-center mt-6 text-blue\"],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"gift\"],[[\"height\",\"class\"],[\"18\",\"fill-current block mr-2\"]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-base\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"products.discountCode\"],[[\"code\",\"value\",\"htmlSafe\"],[\"BYEBYE2022\",\"20\",true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/product-header/tabs.hbs"
    }
  });

  _exports.default = _default;
});