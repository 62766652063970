define("shop/templates/components/product-header/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "epPD7tbz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-col mb-8\"],[8],[0,\"\\n  \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"block btn\"],[3,\"action\",[[23,0,[]],\"addToCart\"]],[8],[0,\"\\n    \"],[1,[28,\"t\",[[28,\"concat\",[\"product.btn.\",[28,\"if\",[[24,[\"product\",\"hasSurcharges\"]],\"chooseLenses\",\"addToCart\"],null]],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"mt-4 \",[28,\"unless\",[[24,[\"htoIsActive\"]],\"hidden\"],null],\" relative\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"htoPopoverIsVisible\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"product-header/hto-popover\",null,[[\"session\",\"hide\"],[[24,[\"session\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"htoPopoverIsVisible\"]]],null],false],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"block btn btn--grey \",[28,\"if\",[[28,\"or\",[[24,[\"htoIsFull\"]],[24,[\"inHto\"]]],null],\"btn--grey\"],null]]]],[3,\"action\",[[23,0,[]],\"addToHto\"]],[8],[0,\"\\n      \"],[1,[28,\"if\",[[24,[\"htoIsFull\"]],[28,\"t\",[\"product.btn.fullHomeTryOn\"],null],[28,\"if\",[[24,[\"inHto\"]],[28,\"t\",[\"product.btn.inHomeTryOn\"],null],[28,\"t\",[\"product.btn.tryAtHome\"],null]],null]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"query\"],[\"block absolute pin-r pin-t pin-b flex items-center px-4\",[28,\"hash\",null,[[\"help\"],[\"homeTryOn\"]]]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"border border-black hover:bg-black text-black hover:text-white p-px rounded-full\"],[8],[0,\"\\n        \"],[1,[28,\"svg-jar\",[\"question\"],[[\"height\",\"class\"],[\"13\",\"fill-current block\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/product-header/actions.hbs"
    }
  });

  _exports.default = _default;
});