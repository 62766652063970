define("shop/models/location", ["exports", "ember-data", "dayjs"], function (_exports, _emberData, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  var _default = Model.extend({
    title: attr('string'),
    googleMapsId: attr('string'),
    tags: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    appointmentSlots: attr('raw', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    workHours: attr('raw', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    openNow: attr('boolean'),
    opensAt: attr('date'),
    closesAt: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    shop: belongsTo('shop'),
    address: belongsTo('address'),
    images: hasMany('image'),
    imageSorting: Ember.A(['position:asc']),
    sortedImages: Ember.computed.sort('images', 'imageSorting'),
    mainImages: Ember.computed.filterBy('sortedImages', 'isMain'),
    newImages: Ember.computed.filterBy('sortedImages', 'isMain'),
    image: Ember.computed.readOnly('newImages.firstObject'),
    footerImages: Ember.computed.filterBy('images', 'isFooter'),
    footerImage: Ember.computed.readOnly('footerImages.firstObject'),
    isPrivate: Ember.computed.not('isPublic'),
    county: Ember.computed.readOnly('address.county'),
    slug: Ember.computed('title', function () {
      return this.title && this.title.dasherize().replace('ă', 'a').replace('ș', 's').replace('ț', 't');
    }),
    isPublic: Ember.computed('tags.[]', function () {
      return !(this.tags || []).includes('private');
    }),
    hasEyeTests: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('eye_tests');
    }),
    isHeadquarter: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('HQ');
    }),
    openToday: Ember.computed('opensAt', function () {
      return (0, _dayjs.default)().isSame(this.opensAt, 'day');
    }),
    schedule: Ember.computed('workHours', function () {
      var _this = this;

      return WEEKDAYS.map(function (day, index) {
        if (!_this.workHours[day]) {
          return;
        }

        var starts = Object.keys(_this.workHours[day]);
        var ends = Object.values(_this.workHours[day]);

        if (!starts[0] || !ends[0]) {
          return;
        }

        return {
          day: (0, _dayjs.default)().weekday(index + 1),
          hours: "".concat(starts[0], " - ").concat(ends[0])
        };
      }).filter(function (d) {
        return d;
      });
    })
  });

  _exports.default = _default;
});