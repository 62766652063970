define("shop/components/checkout-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    progress: Ember.computed('steps.[]', 'completedSteps.[]', function () {
      return (this.completedSteps.length + 1) / this.steps.length * 100;
    })
  });

  _exports.default = _default;
});