define("shop/routes/checkout/payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    activate: function activate() {
      this._super.apply(this, arguments);

      this.controllerFor('checkout').set('currentStep', this.routeName.split('.')[1]);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var order = this.session.order;

      if (!this.session.isAuthenticated) {
        this.transitionTo('checkout.index');
      } else if (!order.hasShippingAddress && !order.isVirtual) {
        this.transitionTo('checkout.shipping');
      } else if (!order.hasBillingAddress && order.isVirtual) {
        this.transitionTo('checkout.billing');
      }
    },
    model: function model() {
      return this.session.order;
    },
    resetController: function resetController() {
      this._super.apply(this, arguments);

      this.session.order.clearCardAttributes();
      this.controller.set('tds');
    }
  });

  _exports.default = _default;
});