define("shop/metrics-adapters/hotjar", ["exports", "ember-metrics/metrics-adapters/base", "ember-metrics/utils/can-use-dom"], function (_exports, _base, _canUseDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'Hotjar';
    },
    init: function init() {
      var id = this.config.id;
      (false && !(id) && Ember.assert("[ember-metrics] You must pass a valid `id` to the ".concat(this.toString(), " adapter"), id));

      if (_canUseDom.default) {
        (function (h, o, t, j, a, r) {
          h.hj = h.hj || function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };

          h._hjSettings = {
            hjid: id,
            hjsv: 6
          };
          a = o.getElementsByTagName('head')[0];
          r = o.createElement('script');
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      }
    },
    trackPage: function trackPage() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      window.hj('stateChange', options.page);
    },
    willDestroy: function willDestroy() {}
  });

  _exports.default = _default;
});