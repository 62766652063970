define("shop/templates/components/ui-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vIbdewN2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mb-4 border border-red rounded p-3 text-red\"],[8],[0,\"\\n  \"],[1,[24,[\"errors\",\"firstObject\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/ui-errors.hbs"
    }
  });

  _exports.default = _default;
});