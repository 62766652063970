define("shop/components/product-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.element.querySelector('.snap-x').scrollLeft = 0;
    }
  });

  _exports.default = _default;
});