define("shop/serializers/customer", ["exports", "shop/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize(typeClass, hash) {
      hash.attributes.meta = hash.meta;
      return this._super(typeClass, hash);
    }
  });

  _exports.default = _default;
});