define("shop/metrics-adapters/log-rocket", ["exports", "ember-metrics/metrics-adapters/base", "ember-metrics/utils/can-use-dom", "logrocket"], function (_exports, _base, _canUseDom, _logrocket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'LogRocket';
    },
    init: function init() {
      var id = this.config.id;
      (false && !(id) && Ember.assert("[ember-metrics] You must pass a valid `id` to the ".concat(this.toString(), " adapter"), id));

      if (_canUseDom.default) {
        _logrocket.default.init(id);
      }
    },
    identify: function identify() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      _logrocket.default.identify(options.distinctId, options);

      return options;
    },
    willDestroy: function willDestroy() {}
  });

  _exports.default = _default;
});