define("shop/routes/products/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GENDERS = ['women', 'men'];

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var _this$paramsFor = this.paramsFor('products'),
          category = _this$paramsFor.category;

      var _this$paramsFor2 = this.paramsFor(this.routeName),
          gender = _this$paramsFor2.gender;

      if (!GENDERS.includes(gender)) {
        this.transitionTo(this.routeName, category, GENDERS[0]);
      } else if (!['glasses', 'sunglasses'].includes(category)) {
        this.transitionTo('shop');
      }
    },
    model: function model(_ref) {
      var gender = _ref.gender;

      var _this$modelFor = this.modelFor('products'),
          products = _this$modelFor.products,
          category = _this$modelFor.category;

      products = products.filter(function (product) {
        return (product.tags || []).includes(gender);
      });
      return {
        products: products,
        category: category,
        gender: gender
      };
    },
    setupController: function setupController(controller, _ref2) {
      var gender = _ref2.gender;

      this._super.apply(this, arguments);

      this.session.persist({
        gender: gender
      });
    },
    headTags: function headTags() {
      var model = this.modelFor(this.routeName);

      if (!Ember.isPresent(model)) {
        return;
      }

      return [{
        type: 'meta',
        tagId: 'og-title',
        attrs: {
          property: 'og:title',
          content: this.intl.t('products.title', {
            gender: model.gender,
            category: model.category
          })
        }
      }, {
        type: 'meta',
        tagId: 'og-type',
        attrs: {
          property: 'og:type',
          content: 'product.group'
        }
      }];
    }
  });

  _exports.default = _default;
});