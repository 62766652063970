define("shop/routes/shops", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    activate: function activate() {
      this._super.apply(this, arguments);

      this.controllerFor('shop').set('layoutIsVisible', false);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.controllerFor('shop').set('layoutIsVisible', true);
    },
    model: function model() {
      return this.store.query('shop', {
        include: 'contact_address,billing_address'
      });
    }
  });

  _exports.default = _default;
});