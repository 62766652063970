define("shop/routes/order/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    afterModel: function afterModel(order) {
      this._super.apply(this, arguments);

      var _this$paramsFor = this.paramsFor(this.routeName),
          reason = _this$paramsFor.reason,
          nps = _this$paramsFor.nps;

      order.set('feedbackReason', reason);
      order.set('feedbackNps', nps);
      order.set('feedbackAt', new Date());
      return order.save();
    }
  });

  _exports.default = _default;
});