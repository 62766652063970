define("shop/templates/order/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i17rgmbX",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"concat\",[\"#\",[24,[\"order\",\"number\"]]],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flex flex-col lg:flex-row min-h-screen\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-1 p-8 lg:p-12\"],[8],[0,\"\\n    \"],[1,[28,\"order-overview\",null,[[\"order\",\"printInvoice\"],[[24,[\"order\"]],[28,\"action\",[[23,0,[]],\"printInvoice\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-1 p-8 lg:p-12 pb-32 shadow-lg\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"max-w-sm mx-auto\"],[8],[0,\"\\n      \"],[1,[28,\"order-summary\",null,[[\"order\"],[[24,[\"order\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"print-wormhole\"]],{\"statements\":[[0,\"  \"],[1,[28,\"order-invoice\",null,[[\"order\"],[[24,[\"order\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/order/index.hbs"
    }
  });

  _exports.default = _default;
});