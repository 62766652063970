define("shop/templates/components/product-surcharges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HDECbdSc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",\"overlay__overlay fixed pin z-40 bg-smoke cursor-default\"],[3,\"action\",[[23,0,[]],[24,[\"hide\"]]]],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"overlay__content fixed pin-r pin-t pin-b z-50 w-full lg:max-w-sm bg-white shadow-md overflow-auto scrolling-touch\"],[8],[0,\"\\n  \"],[1,[28,\"component\",[[28,\"concat\",[\"product-surcharges/\",[24,[\"step\"]]],null]],[[\"lensOptions\",\"filterOptions\",\"surcharges\",\"product\",\"session\",\"lenses\",\"filters\",\"needsLensColor\",\"step\",\"lensColor\",\"totalPrice\",\"addToCart\",\"hide\"],[[24,[\"lensOptions\"]],[24,[\"filterOptions\"]],[24,[\"surcharges\"]],[24,[\"product\"]],[24,[\"session\"]],[24,[\"lenses\"]],[24,[\"filters\"]],[24,[\"needsLensColor\"]],[24,[\"step\"]],[24,[\"lensColor\"]],[24,[\"totalPrice\"]],[28,\"action\",[[23,0,[]],\"addToCart\"],null],[28,\"action\",[[23,0,[]],[24,[\"hide\"]]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/product-surcharges.hbs"
    }
  });

  _exports.default = _default;
});