define("shop/templates/components/press-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c+MjFe3C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-center lg:mt-12 mb-10 lg:mb-24\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"font-medium text-4xl lg:text-8xl leading-narrow tracking-tight mb-2 lg:mb-4\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"press.title\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/press-header.hbs"
    }
  });

  _exports.default = _default;
});