define("shop/templates/reviews", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O2fKe8Pz",
    "block": "{\"symbols\":[\"review\"],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"reviews.title\"],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"px-6 lg:px-12\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"max-w-4xl mx-auto\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"lg:flex lg:items-start lg:relative mb-12\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"lg:w-1/4 pt-4 pb-12 lg:py-12 lg:sticky lg:pin-t text-center lg:text-left\"],[8],[0,\"\\n        \"],[7,\"h1\",true],[10,\"class\",\"text-base font-medium mb-6 lg:mb-8\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"reviews.title\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-2xl tracking-tight px-12 lg:pl-0 lg:pr-20\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"reviews.description\"],[[\"htmlSafe\",\"nps\",\"total\"],[true,[28,\"format-number\",[[28,\"div\",[[24,[\"reviews\",\"meta\",\"avg_nps\"]],2],null]],[[\"maximumFractionDigits\"],[1]]],[28,\"format-number\",[[24,[\"reviews\",\"meta\",\"total_reviews\"]]],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"lg:w-3/4\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-3 -mb-6\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"date:desc\",[24,[\"reviews\"]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"flex w-full lg:w-1/3 px-3 mb-6\"],[8],[0,\"\\n              \"],[1,[28,\"review-item\",null,[[\"review\"],[[23,1,[]]]]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/reviews.hbs"
    }
  });

  _exports.default = _default;
});