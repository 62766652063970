define("shop/mixins/components/overlay", ["exports", "ember-css-transitions/mixins/transition-mixin", "body-scroll-lock", "ember-keyboard"], function (_exports, _transitionMixin, _bodyScrollLock, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_transitionMixin.default, _emberKeyboard.EKMixin, {
    transitionClass: 'overlay',
    keyboardActivated: true,
    contentElement: Ember.computed(function () {
      return this.element.querySelector('.overlay__content');
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      _bodyScrollLock.default.disableBodyScroll(this.contentElement);

      this.on((0, _emberKeyboard.keyUp)('Escape'), function () {
        return _this.hide();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      _bodyScrollLock.default.enableBodyScroll(this.contentElement);
    }
  });

  _exports.default = _default;
});