define("shop/templates/stores", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "56xAnAma",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"px-6 lg:px-12\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"max-w-4xl mx-auto\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"w-full lg:max-w-sm mx-auto pt-4 pb-12 lg:pt-8 lg:pb-16 text-center flex flex-col items-center\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"text-base font-medium mb-6 lg:mb-8\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"stores.title\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text-2xl tracking-tight\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"stores.subtitle\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/stores.hbs"
    }
  });

  _exports.default = _default;
});