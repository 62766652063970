define("shop/templates/stores/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZStL7zbs",
    "block": "{\"symbols\":[\"location\"],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"stores.title\"],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flex flex-col lg:flex-row flex-wrap justify-center -mx-6\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedLocations\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"w-full flex lg:w-1/3 px-6 mb-6 lg:mb-12\"],[8],[0,\"\\n      \"],[1,[28,\"location-item\",null,[[\"location\"],[[23,1,[]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/stores/index.hbs"
    }
  });

  _exports.default = _default;
});