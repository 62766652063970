define("shop/templates/components/product-surcharges/lens-colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vdgHBkbS",
    "block": "{\"symbols\":[\"color\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex items-center -mx-2\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"product\",\"category\",\"lensColors\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"w-1/3 px-2\"],[8],[0,\"\\n      \"],[7,\"div\",false],[12,\"role\",\"button\"],[12,\"class\",[29,[\"flex items-center cursor-pointer border-2 rounded-sm p-3 transition\\n        \",[28,\"if\",[[28,\"eq\",[[23,1,[]],[24,[\"lensColor\"]]],null],\"border-blue\",\"border-grey-lighter hover:border-grey-light\"],null]]]],[3,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"lensColor\"]]],null],[23,1,[]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"block mr-2 h-4 w-4 rounded-full lens--\",[28,\"camelize\",[[23,1,[]]],null]]]],[8],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"font-medium\"],[8],[0,\"\\n          \"],[1,[28,\"t\",[[28,\"concat\",[\"lensColor.\",[28,\"camelize\",[[23,1,[]]],null]],null]],null],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/product-surcharges/lens-colors.hbs"
    }
  });

  _exports.default = _default;
});