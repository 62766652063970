define("shop/adapters/application", ["exports", "ember-data", "shop/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API_URL = _environment.default.API_URL;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    host: API_URL,
    namespace: 'api',
    session: Ember.inject.service(),
    headers: Ember.computed('session.token', function () {
      if (Ember.isPresent(this.session.token)) {
        return {
          'Authorization': "Bearer ".concat(this.session.token)
        };
      }
    })
  });

  _exports.default = _default;
});