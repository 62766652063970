define("shop/routes/shop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    routerScroll: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this$paramsFor = this.paramsFor(this.routeName),
          locale = _this$paramsFor.locale;

      if (!this.session.locales.includes(locale)) {
        this.transitionTo('shop', this.session.locales[0]);
      }
    },
    model: function model(_ref) {
      var locale = _ref.locale;
      this.session.persist({
        locale: locale
      });
      this.session.customer.set('locale', locale);
      this.intl.setLocale([locale]);
    },
    headTags: function headTags() {
      if (!this.controller) {
        return;
      }

      var _this$controller = this.controller,
          menu = _this$controller.menu,
          cart = _this$controller.cart,
          help = _this$controller.help;

      if (menu || cart || help) {
        return [{
          type: 'meta',
          tagId: 'robots',
          attrs: {
            name: 'robots',
            content: 'noindex'
          }
        }];
      }
    },
    actions: {
      queryParamsDidChange: function queryParamsDidChange(_ref2) {
        var menu = _ref2.menu,
            cart = _ref2.cart,
            help = _ref2.help;

        this._super.apply(this, arguments);

        this.routerScroll.set('preserveScrollPosition', menu || cart || help);
      }
    }
  });

  _exports.default = _default;
});