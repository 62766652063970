define("shop/components/product-images", ["exports", "siema"], function (_exports, _siema) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.siema = new _siema.default({
        selector: this.element.querySelector('[data-siema]'),
        startIndex: this.currentImage,
        duration: this.media.isSm ? 200 : 0,
        loop: true,
        onChange: function onChange() {
          _this.onChange(_this.siema.currentSlide);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.siema.destroy();
    },
    actions: {
      prev: function prev() {
        this.siema.prev();
      },
      next: function next() {
        this.siema.next();
      },
      goTo: function goTo(index) {
        this.siema.goTo(index);
      }
    }
  });

  _exports.default = _default;
});