define("shop/templates/components/cta-explore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fSGXVNLe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-center my-12 lg:my-16\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"cta.explore.start\"],null],false],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"models\"],[\"text-black pb-1 border-b border-black\",\"products\",[28,\"array\",[[24,[\"categorySlug\"]],\"women\"],null]]],{\"statements\":[[1,[28,\"t\",[\"cta.explore.women\"],null],false]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"t\",[\"cta.explore.or\"],null],false],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"models\"],[\"text-black pb-1 border-b border-black\",\"products\",[28,\"array\",[[24,[\"categorySlug\"]],\"men\"],null]]],{\"statements\":[[1,[28,\"t\",[\"cta.explore.men\"],null],false]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"t\",[\"cta.explore.end\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/cta-explore.hbs"
    }
  });

  _exports.default = _default;
});