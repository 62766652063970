define("shop/templates/components/main-footer/newsletter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9F/c/8wO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"for\",\"newsletter\"],[10,\"class\",\"block font-medium mb-6 lg:mb-8\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"footer.newsletter.title\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"text-lg lg:text-2xl lg:tracking-tight max-w-sm mb-6 lg:mb-8\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"footer.newsletter.description\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"form\",false],[12,\"class\",\"relative\"],[3,\"action\",[[23,0,[]],\"subscribe\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n  \"],[7,\"input\",true],[11,\"value\",[22,\"email\"]],[10,\"id\",\"newsletter\"],[10,\"class\",\"ctrl border-grey text-white focus:border-white\"],[11,\"placeholder\",[28,\"t\",[\"footer.newsletter.email\"],null]],[11,\"oninput\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"email\"]]],null]],[[\"value\"],[\"target.value\"]]]],[10,\"type\",\"email\"],[8],[9],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"absolute pin-r pin-t pin-b flex items-center\\n    text-grey hover:text-white transition focus:outline-none\"],[10,\"type\",\"submit\"],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"arrow-right\"],[[\"height\",\"class\"],[\"24\",\"fill-current block\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"subscribed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flex items-center mt-4 text-green font-medium\"],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"check\"],[[\"height\",\"class\"],[\"17\",\"fill-current block mr-2\"]]],false],[0,\"\\n    \"],[1,[28,\"t\",[\"footer.newsletter.confirmation\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/main-footer/newsletter.hbs"
    }
  });

  _exports.default = _default;
});