define("shop/components/main-cart/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    order: Ember.computed.readOnly('session.order'),
    shop: Ember.computed.readOnly('order.shop')
  });

  _exports.default = _default;
});