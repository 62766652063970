define("shop/templates/components/main-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vfa6NdAw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex justify-center w-full max-w-4xl lg:max-w-full mx-auto sticky lg:fixed\\n  pin-t lg:pin-none lg:pin-b z-40 lg:pb-8 lg:pointer-events-none\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"query\",\"route\"],[\"flex items-center justify-center\\n  bg-white text-blue shadow-lg lg:rounded mx-auto w-full lg:max-w-xs p-3 lg:pointer-events-auto font-medium\",[28,\"hash\",null,[[\"menu\"],[false]]],\"eye-test\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"block\"],[8],[1,[28,\"t\",[\"alert.eyeTest\"],null],false],[9],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"/images/blink.gif\"],[10,\"class\",\"block mx-2 h-4\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[1,[28,\"t\",[\"alert.schedule\"],null],false],[9],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"arrow-right\"],[[\"width\",\"height\",\"class\"],[\"15\",\"15\",\"fill-current block ml-1\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/main-alert.hbs"
    }
  });

  _exports.default = _default;
});