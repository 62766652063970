define("shop/templates/components/ui-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G4ci1Pjy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"relative\"],[8],[0,\"\\n  \"],[7,\"textarea\",true],[11,\"class\",[29,[\"ctrl resize-none \",[28,\"if\",[[24,[\"error\"]],\"border-red\"],null]]]],[11,\"name\",[22,\"name\"]],[11,\"id\",[22,\"id\"]],[11,\"rows\",[22,\"rows\"]],[11,\"maxlength\",[22,\"maxlength\"]],[11,\"placeholder\",[22,\"placeholder\"]],[11,\"autofocus\",[28,\"if\",[[24,[\"autofocus\"]],\"autofocus\"],null]],[11,\"readonly\",[28,\"if\",[[24,[\"readonly\"]],\"readonly\"],null]],[11,\"oninput\",[28,\"action\",[[23,0,[]],[28,\"optional\",[[24,[\"oninput\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"onblur\",[28,\"action\",[[23,0,[]],[28,\"optional\",[[24,[\"onblur\"]]],null]],[[\"value\"],[\"target.value\"]]]],[8],[1,[22,\"value\"],false],[9],[0,\"\"],[4,\"if\",[[24,[\"maxlength\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"absolute pin-r pin-b py-2 text-grey pointer-events-none\"],[8],[0,\"\\n      \"],[1,[28,\"sub\",[[24,[\"maxlength\"]],[28,\"or\",[[24,[\"value\",\"length\"]],0],null]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/ui-textarea.hbs"
    }
  });

  _exports.default = _default;
});