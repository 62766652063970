define("shop/routes/stores/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var slug = _ref.slug;
      return this.modelFor('stores').findBy('slug', slug);
    }
  });

  _exports.default = _default;
});