define("shop/templates/components/ui-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lhpTg9sl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"class\",[29,[\"ctrl \",[28,\"if\",[[24,[\"error\"]],\"border-red\"],null]]]],[11,\"value\",[22,\"value\"]],[11,\"name\",[22,\"name\"]],[11,\"id\",[22,\"id\"]],[11,\"maxlength\",[22,\"maxlength\"]],[11,\"placeholder\",[22,\"placeholder\"]],[11,\"autofocus\",[28,\"if\",[[24,[\"autofocus\"]],\"autofocus\"],null]],[11,\"autocomplete\",[28,\"if\",[[24,[\"autocomplete\"]],\"on\",\"off\"],null]],[11,\"readonly\",[28,\"if\",[[24,[\"readonly\"]],\"readonly\"],null]],[11,\"oninput\",[28,\"action\",[[23,0,[]],[28,\"optional\",[[24,[\"oninput\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"onblur\",[28,\"action\",[[23,0,[]],[28,\"optional\",[[24,[\"onblur\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"type\",[22,\"type\"]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/ui-input.hbs"
    }
  });

  _exports.default = _default;
});