define("shop/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    redirect: function redirect() {
      var _this$session = this.session,
          shop = _this$session.shop,
          locale = _this$session.locale;
      this.transitionTo('shop', locale || shop.locale);
    }
  });

  _exports.default = _default;
});