define("shop/components/main-help", ["exports", "shop/mixins/components/overlay"], function (_exports, _overlay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_overlay.default, {
    year: new Date().getFullYear()
  });

  _exports.default = _default;
});