define("shop/utils/options-range", ["exports", "numeral"], function (_exports, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = optionsRange;

  function optionsRange(min, max, step, format, symbol) {
    var options = [];

    while (min <= max) {
      options.push({
        value: min,
        label: (min > 0 && symbol ? '+' : '') + (0, _numeral.default)(min).format(format)
      });
      min += step;
    }

    return options;
  }
});