define("shop/templates/components/main-cart/empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z1IAaGSB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"text-center pt-24\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"text-lg font-normal mb-12\"],[8],[0,\"\\n    \"],[1,[28,\"html-safe\",[[28,\"t\",[\"cart.empty\"],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"query\",\"route\",\"models\"],[\"btn btn--outline\",[28,\"hash\",null,[[\"cart\"],[false]]],\"products\",[28,\"array\",[\"glasses\",[28,\"or\",[[24,[\"session\",\"gender\"]],\"women\"],null]],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"t\",[\"cart.btn.getShopping\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/main-cart/empty.hbs"
    }
  });

  _exports.default = _default;
});