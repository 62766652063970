define("shop/components/product-header/variants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    productSizes: Ember.computed('product', 'products', function () {
      return this.products.filterBy('title', this.product.title).filterBy('variant', this.product.variant);
    }),
    productColors: Ember.computed('product', 'products', function () {
      return this.products.filterBy('titleWithSize', this.product.titleWithSize);
    })
  });

  _exports.default = _default;
});