define("shop/templates/components/ui-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UdeTA1vy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[10,\"class\",\"hidden\"],[11,\"id\",[22,\"id\"]],[11,\"checked\",[22,\"checked\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"onclick\"]]],[[\"value\"],[\"target.checked\"]]]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n\"],[7,\"label\",true],[11,\"for\",[22,\"id\"]],[10,\"class\",\"flex items-center text-grey-dark\"],[8],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"error\"]],\"text-red\",[28,\"if\",[[24,[\"checked\"]],\"text-green\",\"text-grey\"],null]],null],\" mr-2 cursor-pointer\"]]],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[[28,\"concat\",[\"checkbox-\",[28,\"if\",[[24,[\"checked\"]],\"marked\",\"blank\"],null],\"-outline\"],null]],[[\"width\",\"height\",\"class\"],[\"21\",\"21\",\"fill-current block\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[22,\"label\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/ui-checkbox.hbs"
    }
  });

  _exports.default = _default;
});