define("shop/templates/components/order-overview/address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wn3rTSxm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"lg:w-2/3 mb-6 lg:mb-12\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"text-xs uppercase tracking-wide mb-4 pb-4 border-b border-grey-lighter\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"order.overview.label.shippingAddress\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"font-medium\"],[8],[1,[28,\"if\",[[24,[\"address\",\"company\"]],[24,[\"address\",\"company\"]],[24,[\"address\",\"fullName\"]]],null],false],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[1,[24,[\"address\",\"address1\"]],false],[0,\", \"],[1,[24,[\"address\",\"address2\"]],false],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[1,[24,[\"address\",\"city\"]],false],[0,\", \"],[1,[24,[\"address\",\"county\"]],false],[0,\", \"],[1,[24,[\"address\",\"country\"]],false],[0,\", \"],[1,[24,[\"address\",\"zip\"]],false],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[1,[24,[\"address\",\"companyNumber\"]],false],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[1,[24,[\"address\",\"phone\"]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/order-overview/address.hbs"
    }
  });

  _exports.default = _default;
});