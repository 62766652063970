define("shop/components/product-surcharges/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mb-6'],
    discount: Ember.computed.readOnly('session.shop.autoDiscount.content'),
    hasDiscount: Ember.computed(function () {
      return this.discount && this.discount.categoryId == this.product.categoryId && this.value == this.discount.surcharge;
    }),
    discountValue: Ember.computed(function () {
      return this.hasDiscount ? this.discount.value / 100 : 0;
    }),
    priceWithDiscount: Ember.computed(function () {
      return this.hasDiscount ? this.price - this.discountValue : this.price;
    }),
    today: Ember.computed(function () {
      return new Date();
    })
  });

  _exports.default = _default;
});