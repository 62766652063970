define("shop/templates/components/product-header/price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "phV7WkMO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex items-center mb-6\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-xl lg:text-2xl font-medium lg:tracking-tight\"],[8],[0,\"\\n    \"],[1,[28,\"format-number\",[[24,[\"product\",\"exchangedPrice\"]]],[[\"format\"],[[24,[\"session\",\"shop\",\"currency\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"product\",\"isGlasses\"]],[24,[\"product\",\"isClipOn\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"border-l border-grey-lighter text-sm pl-4 ml-4 font-medium\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[[28,\"concat\",[\"product.label.\",[28,\"if\",[[24,[\"product\",\"isClipOn\"]],\"clipOnPrice\",\"price\"],null]],null]],[[\"htmlSafe\"],[true]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"product\",\"isOutOfStock\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"mb-6 text-red font-medium\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"product.label.outOfStock\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/product-header/price.hbs"
    }
  });

  _exports.default = _default;
});