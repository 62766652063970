define("shop/metrics-adapters/google-analytics", ["exports", "ember-metrics/metrics-adapters/google-analytics"], function (_exports, _googleAnalytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _googleAnalytics.default.extend({
    init: function init() {
      var id = this.config.id;
      var script = document.createElement('script');
      var scripts = document.getElementsByTagName('script')[0];
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + id;
      scripts.parentNode.insertBefore(script, scripts);
      window.dataLayer = window.dataLayer || [];
      this.gtag('js', new Date());
      this.gtag('config', id);
    },
    identify: function identify() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.gtag('set', {
        user_id: options.distinctId
      });
      return options;
    },
    trackEvent: function trackEvent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.gtag('event', options.event, options);
      return options;
    },
    trackPage: function trackPage() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var id = this.config.id;
      this.gtag('config', id, {
        page_path: options.page
      });
      return options;
    },
    gtag: function gtag() {
      window.dataLayer.push(arguments);
    }
  });

  _exports.default = _default;
});