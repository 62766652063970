define("shop/templates/checkout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FfLEWk9Z",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"checkout.title\"],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flex flex-col lg:flex-row min-h-screen\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-1 p-8 lg:p-12\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"max-w-sm mx-auto\"],[8],[0,\"\\n      \"],[1,[28,\"checkout-header\",null,[[\"session\",\"steps\",\"completedSteps\",\"currentStep\"],[[24,[\"session\"]],[24,[\"steps\"]],[24,[\"completedSteps\"]],[24,[\"currentStep\"]]]]],false],[0,\"\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex-1 p-8 lg:p-12 pb-32 shadow-lg\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"max-w-sm mx-auto\"],[8],[0,\"\\n      \"],[1,[28,\"order-summary\",null,[[\"order\"],[[24,[\"session\",\"order\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/checkout.hbs"
    }
  });

  _exports.default = _default;
});