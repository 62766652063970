define("shop/templates/components/gift-card/delivery-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "guKdr8qb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"bg-grey-lightest text-center lg:min-h-screen flex flex-col justify-center pt-20 pb-12 lg:py-24\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-xs tracking-wide uppercase font-medium text-grey-dark mb-2 lg:mb-4\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"giftCard.step\"],[[\"current\",\"max\"],[2,3]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"text-2xl lg:text-4xl font-medium tracking-tight mb-2 lg:mb-4\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"giftCard.deliveryDate.title\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"max-w-sm mx-auto px-6 text-lg lg:text-xl lg:tracking-tight text-grey-dark mb-8 lg:mb-16\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"giftCard.deliveryDate.subtitle\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"px-6 lg:px-12\"],[8],[0,\"\\n    \"],[1,[28,\"ui-date-picker\",null,[[\"min\",\"max\",\"value\",\"select\"],[[24,[\"min\"]],[24,[\"max\"]],[24,[\"item\",\"giftDeliveryDate\"]],[28,\"action\",[[23,0,[]],[24,[\"select\"]]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/gift-card/delivery-date.hbs"
    }
  });

  _exports.default = _default;
});