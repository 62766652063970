define("shop/components/product-surcharges/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      toggleFilter: function toggleFilter(filter) {
        if (this.filters.includes(filter)) {
          return this.filters.removeObject(filter);
        }

        this.filters.addObject(filter);
      }
    }
  });

  _exports.default = _default;
});