define("shop/mixins/routes/authenticated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (!this.session.isAuthenticated) {
        this.controllerFor('login').set('previousTransition', transition);
        this.transitionTo('login', {
          queryParams: {
            menu: false
          }
        });
      }
    }
  });

  _exports.default = _default;
});