define("shop/models/category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    title: attr('string'),
    surcharges: attr('raw', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    exchangedSurcharges: attr('raw', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),
    lensColors: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    isGlasses: Ember.computed.equal('title', 'Glasses'),
    isSunglasses: Ember.computed.equal('title', 'Sunglasses'),
    isClipOns: Ember.computed.equal('title', 'Clip-ons'),
    slug: Ember.computed('title', function () {
      return this.title && this.title.dasherize();
    })
  });

  _exports.default = _default;
});