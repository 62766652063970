define("shop/components/similar-products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    similarProducts: Ember.computed('products.[]', 'product', function () {
      var _this = this;

      return (this.products || []).filter(function (product) {
        return product.categoryId == _this.product.categoryId && product.tags.includes(_this.session.gender || 'women') && product.title != _this.product.title && !product.isOutOfStock && !product.isSize;
      });
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.element.querySelector('.snap-x').scrollLeft = 0;
    }
  });

  _exports.default = _default;
});