define("shop/routes/order/return/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var order = this.modelFor('order');

      if (!order.hasReturnAddress) {
        this.transitionTo('order.return.edit', order);
      }
    }
  });

  _exports.default = _default;
});