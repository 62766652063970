define("shop/routes/journal/article", ["exports", "jquery", "shop/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ASSETS_URL = _environment.default.ASSETS_URL;

  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var slug = _ref.slug;
      return _jquery.default.get({
        url: "/articles/".concat(slug, ".json"),
        cache: false
      });
    },
    headTags: function headTags() {
      var article = this.modelFor(this.routeName);

      if (!Ember.isPresent(article)) {
        return;
      }

      return [{
        type: 'meta',
        tagId: 'description',
        attrs: {
          name: 'description',
          content: article.description
        }
      }, {
        type: 'meta',
        tagId: 'og-title',
        attrs: {
          property: 'og:title',
          content: article.title
        }
      }, {
        type: 'meta',
        tagId: 'og-description',
        attrs: {
          property: 'og:description',
          content: article.description
        }
      }, {
        type: 'meta',
        tagId: 'og-type',
        attrs: {
          property: 'og:type',
          content: 'article'
        }
      }, {
        type: 'meta',
        tagId: 'og-image',
        attrs: {
          property: 'og:image',
          content: ASSETS_URL + article.cover
        }
      }, {
        type: 'meta',
        tagId: 'og-image-width',
        attrs: {
          property: 'og:image:width',
          content: 1200
        }
      }, {
        type: 'meta',
        tagId: 'og-image-height',
        attrs: {
          property: 'og:image:height',
          content: 1500
        }
      }];
    }
  });

  _exports.default = _default;
});