define("shop/templates/components/product-header/title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WkbFVNWJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex items-center justify-between\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"text-3xl lg:text-4xl font-medium tracking-tight\"],[8],[0,\"\\n    \"],[1,[24,[\"product\",\"title\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"session\",\"nps\"]],\"flex\",\"hidden\"],null],\" items-center\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex items-center font-medium\"],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"star\"],[[\"class\",\"height\"],[\"fill-current block mr-1\",\"13\"]]],false],[0,\"\\n      \"],[1,[28,\"format-number\",[[28,\"div\",[[24,[\"session\",\"nps\"]],2],null]],[[\"maximumFractionDigits\"],[1]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"block ml-3 underline\",\"reviews\"]],{\"statements\":[[0,\"      \"],[1,[28,\"t\",[\"product.btn.reviews\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"text-base text-grey-dark font-medium\"],[8],[0,\"\\n  \"],[1,[24,[\"product\",\"variant\"]],false],[0,\" \"],[1,[28,\"if\",[[24,[\"product\",\"isClipOn\"]],[28,\"t\",[\"product.label.clipOn\"],null]],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/product-header/title.hbs"
    }
  });

  _exports.default = _default;
});