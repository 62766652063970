define("shop/templates/shop/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OACbemU2",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"shop/cover\",null,[[\"session\"],[[24,[\"session\"]]]]],false],[0,\"\\n\"],[1,[28,\"shop/products\",null,[[\"products\",\"category\",\"session\"],[[24,[\"glasses\"]],\"glasses\",[24,[\"session\"]]]]],false],[0,\"\\n\"],[1,[28,\"shop/services\",null,[[\"session\"],[[24,[\"session\"]]]]],false],[0,\"\\n\"],[1,[28,\"shop/products\",null,[[\"products\",\"category\",\"session\"],[[24,[\"sunglasses\"]],\"sunglasses\",[24,[\"session\"]]]]],false],[0,\"\\n\"],[1,[28,\"shop/reviews\",null,[[\"session\",\"reviews\"],[[24,[\"session\"]],[24,[\"reviews\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/shop/index.hbs"
    }
  });

  _exports.default = _default;
});