define("shop/components/product-header/hto-popover", ["exports", "anime"], function (_exports, _anime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['relative'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.timeline = this.createTimeline();
    },
    createTimeline: function createTimeline() {
      return _anime.default.timeline().add({
        targets: this.element,
        easing: 'easeOutCubic',
        delay: _anime.default.stagger(100),
        duration: 400,
        translateY: [10, 0],
        opacity: [0, 1]
      });
    }
  });

  _exports.default = _default;
});