define("shop/controllers/order/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    order: Ember.computed.readOnly('model'),
    actions: {
      printInvoice: function printInvoice() {
        window.print();
      }
    }
  });

  _exports.default = _default;
});