define("shop/components/main-menu", ["exports", "body-scroll-lock", "anime"], function (_exports, _bodyScrollLock, _anime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      _bodyScrollLock.default.disableBodyScroll(this.element);

      this.timeline = this.createTimeline();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      _bodyScrollLock.default.enableBodyScroll(this.element);
    },
    createTimeline: function createTimeline() {
      return _anime.default.timeline().add({
        targets: [document.body, document.documentElement],
        scrollTop: 0,
        duration: 0
      }).add({
        targets: this.children('li'),
        delay: _anime.default.stagger(75),
        easing: 'easeOutCubic',
        duration: 300,
        translateY: [-10, 0],
        opacity: [0, 1]
      });
    },
    children: function children(selector) {
      return this.element.querySelectorAll(selector);
    }
  });

  _exports.default = _default;
});