define("shop/templates/about/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yF9C70Yv",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"about.title\"],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"px-6 lg:px-12\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"max-w-4xl mx-auto\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex flex-col lg:flex-row items-start lg:relative mb-12\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"order-2 lg:order-1 lg:w-1/4 pt-8 lg:pt-12 lg:sticky lg:pin-t border-t lg:border-0 border-grey-lighter\"],[8],[0,\"\\n        \"],[1,[28,\"about/contact\",null,[[\"session\"],[[24,[\"session\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"order-1 lg:order-2 lg:w-3/4\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-2xl lg:text-4xl tracking-tight\"],[8],[0,\"\\n          \"],[7,\"p\",true],[10,\"class\",\"mb-8 lg:mb-12\"],[8],[1,[28,\"t\",[\"about.content.p1\"],null],false],[9],[0,\"\\n          \"],[7,\"p\",true],[10,\"class\",\"mb-8 lg:mb-12\"],[8],[1,[28,\"t\",[\"about.content.p2\"],null],false],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"block mt-12 lg:mt-16 mb-8 lg:mb-12\"],[8],[0,\"\\n            \"],[7,\"img\",true],[10,\"src\",\"/images/9.jpg\"],[10,\"class\",\"block w-full mt-4\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"text-sm lg:text-base mt-4 tracking-normal leading-normal\"],[8],[0,\"\\n              \"],[1,[28,\"t\",[\"about.content.caption\"],null],false],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"p\",true],[10,\"class\",\"mb-8 lg:mb-12\"],[8],[1,[28,\"t\",[\"about.content.p3\"],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/about/index.hbs"
    }
  });

  _exports.default = _default;
});