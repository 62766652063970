define("shop/templates/components/eye-test/locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EpH7gUqQ",
    "block": "{\"symbols\":[\"location\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"px-6 lg:px-12\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"max-w-3xl mx-auto\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"w-full lg:max-w-sm mx-auto pt-4 pb-12 lg:pt-8 lg:pb-16 text-center flex flex-col items-center\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"text-base font-medium mb-6 lg:mb-8\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"eyeTest.header.title\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text-2xl tracking-tight px-6 lg:px-0\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"eyeTest.header.subtitle\"],[[\"htmlSafe\"],[true]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex flex-col lg:flex-row flex-wrap justify-center -mx-6\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedLocations\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"w-full lg:w-1/3 px-6 mb-6 lg:mb-24\"],[8],[0,\"\\n          \"],[1,[28,\"eye-test/location\",null,[[\"location\",\"select\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[24,[\"select\"]]],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/eye-test/locations.hbs"
    }
  });

  _exports.default = _default;
});