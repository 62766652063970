define("shop/templates/components/product-header/hto-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vJ4Im6YF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"click-outside\",null,[[\"onClickOutside\",\"class\"],[[28,\"action\",[[23,0,[]],[24,[\"hide\"]]],null],\"absolute pin-b pin-l pin-r mb-4 flex items-center justify-center\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"lg:w-64 lg:flex-none p-6 shadow rounded-sm border border-blue bg-white\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex items-center mb-2\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"flex-auto font-medium text-base\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"product.htoPopover.title\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"block text-black p-1 -m-1\"],[3,\"action\",[[23,0,[]],[24,[\"hide\"]]]],[8],[0,\"\\n        \"],[1,[28,\"svg-jar\",[\"close\"],[[\"height\",\"class\"],[\"15\",\"fill-current block\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-grey-dark\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"product.htoPopover.description\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/product-header/hto-popover.hbs"
    }
  });

  _exports.default = _default;
});