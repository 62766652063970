define("shop/templates/components/checkout-tds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6rimNnYP",
    "block": "{\"symbols\":[\"value\",\"name\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"fixed pin z-50 overflow-auto bg-smoke flex scrolling-touch\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"lg:max-w-md m-auto bg-white shadow-lg w-full min-h-full lg:min-h-0\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex items-center bg-grey-lightest p-6 lg:px-8\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"flex-auto text-lg font-normal\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"checkout.payment.tdsTitle\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"block text-black -m-2 p-2\"],[3,\"action\",[[23,0,[]],[24,[\"close\"]]]],[8],[0,\"\\n        \"],[1,[28,\"svg-jar\",[\"close\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"overflow-scroll\"],[8],[0,\"\\n      \"],[7,\"iframe\",true],[10,\"class\",\"block mx-auto\"],[10,\"width\",\"480\"],[10,\"height\",\"480\"],[10,\"name\",\"tds\"],[10,\"id\",\"tds\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"form\",true],[10,\"class\",\"hidden\"],[11,\"action\",[22,\"url\"]],[10,\"method\",\"post\"],[10,\"target\",\"tds\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"-each-in\",[[24,[\"data\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"input\",true],[11,\"name\",[23,2,[]]],[11,\"value\",[23,1,[]]],[10,\"type\",\"hidden\"],[8],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/checkout-tds.hbs"
    }
  });

  _exports.default = _default;
});