define("shop/templates/stores/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "60Bhkn4X",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"location.title\"],[[\"city\"],[[24,[\"location\",\"address\",\"city\"]]]]]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"flex flex-col lg:flex-row flex-wrap justify-center -mx-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-full flex lg:w-1/3 px-6 mb-6 lg:mb-12\"],[8],[0,\"\\n    \"],[1,[28,\"location-item\",null,[[\"location\"],[[24,[\"location\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/stores/location.hbs"
    }
  });

  _exports.default = _default;
});