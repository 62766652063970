define("shop/templates/components/order-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/VtcVhCL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"max-w-sm mx-auto\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"block text-black mb-8 lg:mb-12\",\"shop\"]],{\"statements\":[[0,\"    \"],[1,[28,\"svg-jar\",[\"logo\"],[[\"class\",\"width\",\"height\"],[\"fill-current block mx-auto\",\"76\",\"30\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-lg text-grey-dark\"],[8],[0,\"\\n    #\"],[1,[24,[\"order\",\"number\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"text-2xl mb-6\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"order.title\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"order-overview/status\",null,[[\"order\"],[[24,[\"order\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"order\",\"isVirtual\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"order-overview/gift\",null,[[\"item\"],[[24,[\"order\",\"items\",\"firstObject\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"order\",\"hasShippingAddress\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"order-overview/address\",null,[[\"address\"],[[24,[\"order\",\"shippingAddress\"]]]]],false],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[1,[28,\"order-overview/actions\",null,[[\"order\",\"printInvoice\"],[[24,[\"order\"]],[28,\"action\",[[23,0,[]],[24,[\"printInvoice\"]]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/order-overview.hbs"
    }
  });

  _exports.default = _default;
});