define("shop/components/ui-date-picker/header", ["exports", "dayjs"], function (_exports, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: Ember.computed('active', function () {
      return (0, _dayjs.default)(this.active).format('MMMM YYYY');
    })
  });

  _exports.default = _default;
});