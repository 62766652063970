define("shop/templates/checkout/prescription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Se+NLpPO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"text-xl mb-4\"],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"checkout.prescription.title\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/checkout/prescription.hbs"
    }
  });

  _exports.default = _default;
});