define("shop/components/gift-card/delivery-date", ["exports", "dayjs"], function (_exports, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('min', (0, _dayjs.default)().toDate());
      this.set('max', (0, _dayjs.default)().add(3, 'months').toDate());
    }
  });

  _exports.default = _default;
});