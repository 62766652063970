define("shop/routes/page", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PAGES = ['terms-of-service', 'privacy-policy'];

  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var slug = _ref.slug;

      if (PAGES.includes(slug)) {
        return _jquery.default.get({
          url: "/pages/".concat(slug, ".md"),
          cache: false
        });
      }

      this.transitionTo('shop');
    }
  });

  _exports.default = _default;
});