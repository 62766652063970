define("shop/templates/checkout/billing/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4myCLy+r",
    "block": "{\"symbols\":[\"option\",\"index\"],\"statements\":[[7,\"form\",false],[12,\"class\",\"mt-6\"],[3,\"action\",[[23,0,[]],\"save\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\"],[4,\"each\",[[28,\"array\",[\"sameAsShipping\",\"differentAddress\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"flex items-center mb-4\"],[8],[0,\"\\n      \"],[7,\"input\",true],[11,\"checked\",[28,\"eq\",[[24,[\"selectedOption\"]],[23,2,[]]],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selectedOption\"]]],null],[23,2,[]]],null]],[11,\"id\",[28,\"concat\",[\"option-\",[23,2,[]]],null]],[10,\"name\",\"option\"],[11,\"value\",[23,2,[]]],[10,\"class\",\"hidden\"],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n      \"],[7,\"label\",true],[11,\"for\",[28,\"concat\",[\"option-\",[23,2,[]]],null]],[10,\"class\",\"flex items-center text-lg\"],[8],[0,\"\\n        \"],[1,[28,\"svg-jar\",[[28,\"if\",[[28,\"eq\",[[24,[\"selectedOption\"]],[23,2,[]]],null],\"radiobox-marked\",\"radiobox-blank\"],null]],[[\"class\",\"width\",\"height\"],[\"fill-current block mr-2\",\"20\",\"20\"]]],false],[0,\"\\n        \"],[1,[28,\"t\",[[28,\"concat\",[\"checkout.billing.option.\",[23,1,[]]],null]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flex items-center mt-8\"],[8],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"btn\"],[10,\"type\",\"submit\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"checkout.billing.btn.save\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/checkout/billing/index.hbs"
    }
  });

  _exports.default = _default;
});