define("shop/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yPKOKeX3",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"head-layout\"],false],[0,\"\\n\"],[1,[22,\"ember-load-remover\"],false],[0,\"\\n\"],[1,[22,\"progress-bar\"],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/application.hbs"
    }
  });

  _exports.default = _default;
});