define("shop/templates/components/order-summary/hto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TJmgg4Y6",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-b border-grey-lighter pb-6 mb-6\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex mb-6\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"text-base\"],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"order.summary.label.hto\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex -mx-2\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"map-by\",[\"product\",[28,\"sort-by\",[\"createdAt:asc\",[24,[\"order\",\"htoItems\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"w-1/4 px-2\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"models\"],[\"block\",\"products.product\",[28,\"array\",[[23,1,[\"categorySlug\"]],[23,1,[\"titleSlug\"]],[23,1,[\"variantSlug\"]]],null]]],{\"statements\":[[0,\"          \"],[7,\"img\",true],[11,\"src\",[23,1,[\"image\",\"smUrl\"]]],[10,\"class\",\"block w-full\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/order-summary/hto.hbs"
    }
  });

  _exports.default = _default;
});