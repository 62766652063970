define("shop/routes/about/index", ["exports", "shop/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ASSETS_URL = _environment.default.ASSETS_URL;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    headTags: function headTags() {
      return [{
        type: 'meta',
        tagId: 'og-title',
        attrs: {
          property: 'og:title',
          content: this.intl.t('about.title')
        }
      }, {
        type: 'meta',
        tagId: 'og-type',
        attrs: {
          property: 'og:type',
          content: 'website'
        }
      }, {
        type: 'meta',
        tagId: 'og-image',
        attrs: {
          property: 'og:image',
          content: ASSETS_URL + '/images/13.jpg'
        }
      }, {
        type: 'meta',
        tagId: 'og-image-width',
        attrs: {
          property: 'og:image:width',
          content: 1200
        }
      }, {
        type: 'meta',
        tagId: 'og-image-height',
        attrs: {
          property: 'og:image:height',
          content: 1500
        }
      }, {
        type: 'meta',
        tagId: 'og-image-type',
        attrs: {
          property: 'og:image:type',
          content: 'image/jpeg'
        }
      }];
    }
  });

  _exports.default = _default;
});