define("shop/routes/order", ["exports", "shop/mixins/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticated.default, {
    activate: function activate() {
      this._super.apply(this, arguments);

      this.controllerFor('shop').set('layoutIsVisible', false);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.controllerFor('shop').set('layoutIsVisible', true);
    }
  });

  _exports.default = _default;
});