define("shop/formats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    date: {
      date: {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
      },
      datetime: {
        weekday: 'long',
        month: 'long',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }
    },
    number: {
      EUR: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      },
      RON: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }
    }
  };
  _exports.default = _default;
});