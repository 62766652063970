define("shop/components/product-header/tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeTab: 'benefits',
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.set('activeTab', 'benefits');
    }
  });

  _exports.default = _default;
});