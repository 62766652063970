define("shop/models/review", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    nps: attr('number'),
    review: attr('string'),
    date: attr('date'),
    reason: attr('string'),
    name: attr('string'),
    isHto: attr('boolean'),
    products: hasMany('product'),
    rating: Ember.computed('nps', function () {
      if (this.nps) {
        return parseInt(this.nps) / 2;
      }
    })
  });

  _exports.default = _default;
});