define("shop/controllers/checkout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STEPS = _exports.PAYMENT_STEP = _exports.PRESCRIPTION_STEP = _exports.BILLING_STEP = _exports.SHIPPING_STEP = void 0;
  var SHIPPING_STEP = 'shipping';
  _exports.SHIPPING_STEP = SHIPPING_STEP;
  var BILLING_STEP = 'billing';
  _exports.BILLING_STEP = BILLING_STEP;
  var PRESCRIPTION_STEP = 'prescription';
  _exports.PRESCRIPTION_STEP = PRESCRIPTION_STEP;
  var PAYMENT_STEP = 'payment';
  _exports.PAYMENT_STEP = PAYMENT_STEP;
  var STEPS = [SHIPPING_STEP, BILLING_STEP, PRESCRIPTION_STEP, PAYMENT_STEP];
  _exports.STEPS = STEPS;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    counties: Ember.computed.readOnly('model.counties'),
    isVirtual: Ember.computed.readOnly('session.order.isVirtual'),
    needsPrescription: Ember.computed.readOnly('session.order.needsPrescription'),
    needsBillingAddress: Ember.computed.readOnly('session.order.needsBillingAddress'),
    isHto: Ember.computed.readOnly('session.order.isHto'),
    steps: Ember.computed('needsPrescription', function () {
      var _this = this;

      return STEPS.reject(function (step) {
        return step == SHIPPING_STEP && _this.isVirtual || step == PRESCRIPTION_STEP && !_this.needsPrescription || step == BILLING_STEP && !_this.needsBillingAddress;
      });
    }),
    completedSteps: Ember.computed('steps.[]', 'currentStep', function () {
      return this.steps.slice(0, this.steps.indexOf(this.currentStep));
    })
  });

  _exports.default = _default;
});