define("shop/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n2SJQG5V",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"max-w-md mx-auto mt-24 mb-32 lg:mb-48 px-12 text-center\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[10,\"class\",\"text-lg lg:text-2xl mb-2\"],[8],[1,[28,\"t\",[\"error.title\"],null],false],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"text-lg lg:text-2xl\"],[8],[1,[28,\"t\",[\"error.description\"],[[\"htmlSafe\"],[true]]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/error.hbs"
    }
  });

  _exports.default = _default;
});