define("shop/models/discount", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    code: attr('string'),
    kind: attr('string', {
      defaultValue: 'percentage'
    }),
    startsAt: attr('date'),
    endsAt: attr('date'),
    value: attr('number', {
      defaultValue: 0
    }),
    minItemCount: attr('number', {
      defaultValue: 0
    }),
    surcharge: attr('string'),
    shop: belongsTo('shop'),
    category: belongsTo('category'),
    categoryId: Ember.computed.readOnly('category.id'),
    isPercentage: Ember.computed.equal('kind', 'percentage')
  });

  _exports.default = _default;
});