define("shop/components/eye-test/slots", ["exports", "dayjs"], function (_exports, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    from: 0,
    activeDay: null,
    slots: Ember.computed.readOnly('location.appointmentSlots'),
    days: Ember.computed('slots', function () {
      var _this = this;

      return Object.keys(this.slots).map(function (day) {
        return {
          slots: _this.slots[day],
          day: day
        };
      });
    }),
    visibleDays: Ember.computed('media.isSm', function () {
      return this.media.isSm ? 3 : 5;
    }),
    hasNext: Ember.computed('from', 'visibleDays', 'days', function () {
      return this.from < this.days.length - this.visibleDays;
    }),
    hasPrev: Ember.computed('from', function () {
      return this.from > 0;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('activeDay', this.days.firstObject);
      this.appointment.set('startsAt', null);
    },
    actions: {
      selectTime: function selectTime(time) {
        var day = this.activeDay.day;
        var timeZone = this.get('appointment.shop.timeZoneName');

        var date = _dayjs.default.tz("".concat(day, " ").concat(time), timeZone);

        this.select(date.toDate());
      },
      showNext: function showNext() {
        if (this.hasNext) {
          this.set('from', this.from + 1);
        }
      },
      showPrev: function showPrev() {
        if (this.hasPrev) {
          this.set('from', this.from - 1);
        }
      }
    }
  });

  _exports.default = _default;
});