define("shop/templates/components/checkout-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Xzljh5l",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\"],[\"block text-black mb-8 lg:mb-12\",\"shop\"]],{\"statements\":[[0,\"  \"],[1,[28,\"svg-jar\",[\"logo\"],[[\"class\",\"width\",\"height\"],[\"fill-current block mx-auto\",\"76\",\"30\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[24,[\"currentStep\"]],[28,\"gt\",[[24,[\"steps\",\"length\"]],1],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flex text-sm lg:text-base relative mb-8 lg:mb-12\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"steps\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"checkout-header/step\",null,[[\"session\",\"step\",\"isCompleted\",\"isCurrent\"],[[24,[\"session\"]],[23,1,[]],[28,\"contains\",[[23,1,[]],[24,[\"completedSteps\"]]],null],[28,\"eq\",[[23,1,[]],[24,[\"currentStep\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"absolute pin-l pin-r pin-b bg-grey-light\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"bg-black transition h-px\"],[11,\"style\",[28,\"html-safe\",[[28,\"concat\",[\"width: \",[24,[\"progress\"]],\"%;\"],null]],null]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/checkout-header.hbs"
    }
  });

  _exports.default = _default;
});