define("shop/templates/components/article-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LSJxlF03",
    "block": "{\"symbols\":[\"article\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-wrap justify-center -mx-6 mb-12 lg:mb-24\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"articles\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"w-full lg:w-1/3 px-6 mb-12\"],[10,\"data-content\",\"\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"block\",\"journal.article\",[23,1,[\"slug\"]]]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"relative pt-5/4 bg-grey-lightest mb-6\"],[8],[0,\"\\n          \"],[7,\"img\",true],[11,\"src\",[23,1,[\"cover\"]]],[10,\"class\",\"absolute pin\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"h2\",true],[10,\"class\",\"text-lg leading-tight font-medium mb-2\"],[8],[0,\"\\n          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-sm text-grey-dark\"],[8],[0,\"\\n          \"],[1,[28,\"html-safe\",[[28,\"markdown-to-html\",[[23,1,[\"subtitle\"]]],null]],null],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/article-list.hbs"
    }
  });

  _exports.default = _default;
});