define("shop/templates/components/main-footer/legal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7U8ZDonD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-col lg:flex-row lg:items-center lg:pb-12\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"order-2 lg:order-1 lg:mr-6\"],[8],[0,\"\\n    \"],[1,[28,\"html-safe\",[[28,\"t\",[\"footer.legal.copyright\"],[[\"year\"],[[24,[\"year\"]]]]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"order-1 lg:order-2 flex items-center mb-4 lg:mb-0\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"block text-white mr-4 lg:mr-6\",\"page\",\"terms-of-service\"]],{\"statements\":[[1,[28,\"t\",[\"footer.legal.terms\"],null],false]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"block text-white mr-4 lg:mr-6\",\"page\",\"privacy-policy\"]],{\"statements\":[[1,[28,\"t\",[\"footer.legal.privacy\"],null],false]],\"parameters\":[]},null],[0,\"    \"],[7,\"a\",true],[10,\"href\",\"https://anpc.ro/\"],[10,\"rel\",\"noopener\"],[10,\"target\",\"_blank\"],[10,\"class\",\"block text-white\"],[8],[1,[28,\"t\",[\"footer.legal.anpc\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/main-footer/legal.hbs"
    }
  });

  _exports.default = _default;
});