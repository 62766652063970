define("shop/templates/components/product-surcharges/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ySpaKYxq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex flex-col justify-end pt-1/3 relative overflow-hidden\"],[8],[0,\"\\n  \"],[7,\"img\",true],[11,\"src\",[24,[\"product\",\"image\",\"originalUrl\"]]],[10,\"class\",\"absolute w-full pin-t -mt-1/2 z-10\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"relative z-20 w-full flex items-center justify-between py-6 px-8\"],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"text-black -m-2 p-2\"],[3,\"action\",[[23,0,[]],[24,[\"goBack\"]]]],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"arrow-left\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"text-lg font-medium\"],[8],[0,\"\\n      \"],[1,[22,\"title\"],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"a\",false],[12,\"role\",\"button\"],[12,\"class\",\"text-black -m-2 p-2\"],[3,\"action\",[[23,0,[]],[24,[\"close\"]]]],[8],[0,\"\\n      \"],[1,[28,\"svg-jar\",[\"close\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/product-surcharges/header.hbs"
    }
  });

  _exports.default = _default;
});