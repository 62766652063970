define("shop/utils/scroll-to", ["exports", "anime"], function (_exports, _anime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollTo;

  function scrollTo(id) {
    var element = document.getElementById(id);
    var elementOffset = element.getBoundingClientRect().top;
    var scrollOffset = elementOffset + window.scrollY;
    (0, _anime.default)({
      targets: [document.body, document.documentElement],
      scrollTop: scrollOffset,
      duration: 400,
      easing: 'easeInOutQuad'
    });
  }
});