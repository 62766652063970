define("shop/components/checkout-tds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.addListeners();
      this.scrollToCenter();
      this.submitForm();
    },
    addListeners: function addListeners() {
      var _this = this;

      window.addEventListener('message', function (event) {
        if (event.data && event.data.pares) {
          _this.set('changeset.pares', event.data.pares);

          _this.success();

          _this.close();
        }
      });
    },
    scrollToCenter: function scrollToCenter() {
      var iFrame = this.element.querySelector('iframe');
      iFrame.scrollIntoView({
        inline: 'center'
      });
    },
    submitForm: function submitForm() {
      this.element.querySelector('form').submit();
    }
  });

  _exports.default = _default;
});