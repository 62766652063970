define("shop/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.session.isAuthenticated) {
        this.transitionTo('account', {
          queryParams: {
            menu: false
          }
        });
      }
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.reset();
      }
    }
  });

  _exports.default = _default;
});