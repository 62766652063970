define("shop/templates/components/order-summary/gift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fgqR8RpA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"border-b border-grey-lighter pb-6 mb-6\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex -mx-2\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"w-1/4 px-2\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[24,[\"item\",\"product\",\"image\",\"smUrl\"]]],[10,\"class\",\"block w-full\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex-auto px-2 py-2 lg:py-4\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"flex items-center mb-2\"],[8],[0,\"\\n        \"],[7,\"h4\",true],[10,\"class\",\"flex-auto text-base\"],[8],[0,\"\\n          \"],[1,[24,[\"item\",\"product\",\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[1,[28,\"format-number\",[[24,[\"item\",\"price\"]]],[[\"format\"],[[24,[\"item\",\"order\",\"currency\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"list-reset text-grey-dark text-sm\"],[8],[0,\"\\n        \"],[7,\"li\",true],[8],[1,[24,[\"item\",\"giftRecipient\"]],false],[0,\", \"],[1,[24,[\"item\",\"giftRecipientEmail\"]],false],[9],[0,\"\\n        \"],[7,\"li\",true],[8],[1,[28,\"format-date\",[[24,[\"item\",\"giftDeliveryDate\"]]],[[\"format\"],[\"date\"]]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/order-summary/gift.hbs"
    }
  });

  _exports.default = _default;
});