define("shop/templates/components/ui-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cZZwYvK3",
    "block": "{\"symbols\":[\"day\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"max-w-sm mx-auto\"],[8],[0,\"\\n  \"],[1,[28,\"ui-date-picker/header\",null,[[\"active\",\"showNextMonth\",\"showPreviousMonth\"],[[24,[\"active\"]],[28,\"action\",[[23,0,[]],\"showNextMonth\"],null],[28,\"action\",[[23,0,[]],\"showPreviousMonth\"],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex flex-wrap -mx-1 mb-2\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"days\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"w-1/7 px-1 mb-2\"],[8],[0,\"\\n        \"],[1,[28,\"ui-date-picker/day\",null,[[\"day\",\"value\",\"active\",\"min\",\"max\",\"select\"],[[23,1,[]],[24,[\"value\"]],[24,[\"active\"]],[24,[\"min\"]],[24,[\"max\"]],[28,\"action\",[[23,0,[]],[24,[\"select\"]]],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/ui-date-picker.hbs"
    }
  });

  _exports.default = _default;
});