define("shop/templates/gift-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IL8nYWGd",
    "block": "{\"symbols\":[\"changeset\"],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[\"giftCard.pageTitle\"],null]],null],false],[0,\"\\n\"],[4,\"with\",[[28,\"changeset\",[[24,[\"item\"]],[24,[\"GiftValidator\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"gift-card/header\",null,[[\"id\",\"session\",\"select\"],[\"header\",[24,[\"session\"]],[28,\"action\",[[23,0,[]],\"selectType\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"item\",\"giftType\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"gift-card/products\",null,[[\"id\",\"session\",\"products\",\"item\",\"select\"],[\"products\",[24,[\"session\"]],[24,[\"products\"]],[24,[\"item\"]],[28,\"action\",[[23,0,[]],\"selectProduct\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"item\",\"product\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"gift-card/delivery-date\",null,[[\"id\",\"session\",\"item\",\"select\"],[\"delivery-date\",[24,[\"session\"]],[24,[\"item\"]],[28,\"action\",[[23,0,[]],\"selectDeliveryDate\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"item\",\"giftDeliveryDate\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"gift-card/recipient\",null,[[\"id\",\"session\",\"changeset\",\"isSaving\",\"addToCart\"],[\"recipient\",[24,[\"session\"]],[23,1,[]],[24,[\"isSaving\"]],[28,\"action\",[[23,0,[]],\"addToCart\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/gift-card.hbs"
    }
  });

  _exports.default = _default;
});