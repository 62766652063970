define("shop/helpers/format-number", ["exports", "ember-intl/helpers/format-number"], function (_exports, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _formatNumber.default.extend({
    session: Ember.inject.service(),
    format: function format(value, _ref) {
      var _format = _ref.format;

      var output = this._super.apply(this, arguments);

      if (_format == 'RON') {
        output += this.session.locale == 'hu' ? ' lej' : ' lei';
      } else if (_format == 'EUR') {
        output = '€' + output;
      }

      return output;
    }
  });

  _exports.default = _default;
});