define("shop/routes/checkout/billing/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var order = this.session.order;

      if (order.isVirtual) {
        this.transitionTo('checkout.billing.edit', {
          queryParams: {
            menu: false,
            cart: false,
            help: ''
          }
        });
      }
    }
  });

  _exports.default = _default;
});