define("shop/templates/components/ui-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9rg/s9AJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"relative\"],[8],[0,\"\\n  \"],[1,[28,\"one-way-select\",[[24,[\"selectedValue\"]]],[[\"class\",\"id\",\"autocomplete\",\"value\",\"options\",\"optionValuePath\",\"optionLabelPath\",\"prompt\",\"promptIsSelectable\",\"required\",\"update\"],[[28,\"concat\",[\"ctrl\",[28,\"if\",[[24,[\"error\"]],\" border-red\"],null],[28,\"unless\",[[24,[\"value\"]],\" ctrl--empty\"],null]],null],[24,[\"id\"]],[28,\"if\",[[24,[\"autocomplete\"]],\"on\",\"off\"],null],[24,[\"value\"]],[24,[\"options\"]],[24,[\"optionValuePath\"]],[24,[\"optionLabelPath\"]],[24,[\"prompt\"]],[24,[\"promptIsSelectable\"]],[24,[\"required\"]],[28,\"action\",[[23,0,[]],[24,[\"onchange\"]]],null]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pointer-events-none absolute pin-y pin-r flex items-center text-grey\"],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"chevron-down\"],[[\"width\",\"height\",\"class\"],[\"18\",\"18\",\"fill-current block\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/ui-select.hbs"
    }
  });

  _exports.default = _default;
});