define("shop/components/ui-date-picker/day", ["exports", "dayjs"], function (_exports, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isSelectable: Ember.computed('min', 'max', 'day', function () {
      var date = (0, _dayjs.default)(this.day);
      return date.isBefore(this.max) && date.add(1, 'day').isAfter(this.min, 'day');
    }),
    isSelected: Ember.computed('day', 'value', function () {
      return this.value && (0, _dayjs.default)(this.value).isSame(this.day, 'day');
    }),
    isToday: Ember.computed('day', function () {
      return (0, _dayjs.default)().isSame(this.day, 'day');
    }),
    isInMonth: Ember.computed('day', 'active', function () {
      return (0, _dayjs.default)(this.day).isSame(this.active, 'month');
    }),
    title: Ember.computed('day', function () {
      return (0, _dayjs.default)(this.day).format('D');
    }),
    actions: {
      select: function select() {
        if (this.isSelectable) {
          this.select((0, _dayjs.default)(this.day).hour(12).toDate());
        }
      }
    }
  });

  _exports.default = _default;
});