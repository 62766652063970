define("shop/components/checkout-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    monthOptions: Ember.computed(function () {
      var options = [];

      for (var i = 1; i <= 12; i++) {
        options.push(('0' + i).slice(-2));
      }

      return options;
    }),
    yearOptions: Ember.computed(function () {
      var options = [];
      var year = new Date().getFullYear();

      for (var i = year; i <= year + 10; i++) {
        options.push(i);
      }

      return options;
    })
  });

  _exports.default = _default;
});