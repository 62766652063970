define("shop/components/main-countdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    tagName: '',
    discount: Ember.computed.readOnly('session.shop.autoDiscount'),
    endsAt: Ember.computed.readOnly('discount.endsAt'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.interval = setInterval(function () {
        var now = new Date().getTime();
        var distance = _this.endsAt.getTime() - now;

        _this.set('countdown', {
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)),
          minutes: Math.floor(distance % (1000 * 60 * 60) / (1000 * 60)),
          seconds: Math.floor(distance % (1000 * 60) / 1000)
        });
      }, 1000);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      clearInterval(this.interval);
    }
  });

  _exports.default = _default;
});