define("shop/components/ui-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isVisible: Ember.computed.notEmpty('errors'),
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      window.scrollTo(0, 0);
    }
  });

  _exports.default = _default;
});