define("shop/components/article-list", ["exports", "anime"], function (_exports, _anime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.timeline = this.createTimeline();
    },
    createTimeline: function createTimeline() {
      return _anime.default.timeline().add({
        targets: this.children('[data-content]'),
        easing: 'easeOutCubic',
        delay: _anime.default.stagger(200),
        duration: 800,
        translateY: [20, 0],
        opacity: [0, 1]
      });
    },
    children: function children(selector) {
      return this.element.querySelectorAll(selector);
    }
  });

  _exports.default = _default;
});