define("shop/templates/jobs/job", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d4agHmdY",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[[28,\"t\",[[28,\"concat\",[\"jobs.\",[28,\"camelize\",[[24,[\"model\",\"slug\"]]],null],\".title\"],null]],null]],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"px-6 lg:px-12\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"max-w-4xl mx-auto\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex flex-col lg:flex-row items-start lg:relative mb-12\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"order-2 lg:order-1 lg:w-1/4 pt-8 lg:pt-12 lg:sticky lg:pin-t border-t lg:border-0 border-grey-lighter\"],[8],[0,\"\\n        \"],[1,[28,\"about/contact\",null,[[\"session\"],[[24,[\"session\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"order-1 lg:order-2 lg:w-3/4 mt-6 lg:mt-12 mb-12\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"copy lg:copy--xl mb-12\"],[8],[0,\"\\n          \"],[1,[28,\"html-safe\",[[28,\"markdown-to-html\",[[24,[\"model\",\"content\"]]],null]],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"a\",true],[11,\"href\",[28,\"t\",[[28,\"concat\",[\"jobs.\",[28,\"camelize\",[[24,[\"model\",\"slug\"]]],null],\".form\"],null]],null]],[10,\"class\",\"btn text-lg\"],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"flex items-center\"],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"mr-2\"],[8],[1,[28,\"t\",[\"jobs.btn.apply\"],null],false],[9],[0,\"\\n            \"],[1,[28,\"svg-jar\",[\"arrow-right\"],[[\"width\",\"height\",\"class\"],[\"15\",\"15\",\"fill-current block\"]]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/jobs/job.hbs"
    }
  });

  _exports.default = _default;
});