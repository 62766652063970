define("shop/controllers/shop/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    products: Ember.computed.readOnly('model.products'),
    reviews: Ember.computed.readOnly('model.reviews'),
    glasses: Ember.computed.filterBy('products', 'isGlasses'),
    sunglasses: Ember.computed.filterBy('products', 'isSunglasses')
  });

  _exports.default = _default;
});