define("shop/templates/components/similar-products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SN72/BuR",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"max-w-4xl mx-auto\"],[8],[0,\"\\n  \"],[7,\"h3\",true],[10,\"class\",\"text-xl font-medium text-center mb-8 lg:mb-16\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"product.similar.title\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"relative w-full lg:w-auto flex snap-x overflow-x-auto scrollbar-hide lg:-mx-3 pl-4 lg:pl-0\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"slice\",[0,[28,\"if\",[[24,[\"media\",\"isSm\"]],12,4],null],[28,\"shuffle\",[[24,[\"similarProducts\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"w-3/4 lg:w-1/4 pl-4 lg:px-3 flex-no-shrink\"],[8],[0,\"\\n        \"],[1,[28,\"product-item\",null,[[\"product\",\"gender\"],[[23,1,[]],[28,\"or\",[[24,[\"session\",\"gender\"]],\"women\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n  \"],[1,[28,\"cta-explore\",null,[[\"categorySlug\"],[[24,[\"product\",\"categorySlug\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/similar-products.hbs"
    }
  });

  _exports.default = _default;
});