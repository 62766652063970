define("shop/models/order", ["exports", "ember-data", "numeral"], function (_exports, _emberData, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var bool = Ember.computed.bool,
      gt = Ember.computed.gt,
      lte = Ember.computed.lte,
      filterBy = Ember.computed.filterBy,
      notEmpty = Ember.computed.notEmpty,
      setDiff = Ember.computed.setDiff,
      readOnly = Ember.computed.readOnly,
      mapBy = Ember.computed.mapBy,
      or = Ember.computed.or,
      not = Ember.computed.not;

  var _default = Model.extend({
    currency: attr('string'),
    number: attr('number'),
    total: attr('number'),
    invoiceNumber: attr('number'),
    discountCode: attr('string'),
    discountPrice: attr('number', {
      defaultValue: 0
    }),
    shippingPrice: attr('number', {
      defaultValue: 0
    }),
    trackingNumber: attr('string'),
    returnTrackingNumber: attr('string'),
    requestReturn: attr('boolean'),
    cardNumber: attr('string'),
    cardExpiryMonth: attr('string'),
    cardExpiryYear: attr('string'),
    cardCode: attr('string'),
    vivaOrderId: attr('string'),
    pares: attr('string'),
    fulfilledAt: attr('date'),
    canceledAt: attr('date'),
    returnedAt: attr('date'),
    paidAt: attr('date'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    returnPickupAt: attr('date'),
    checkout: attr('boolean', {
      defaultValue: false
    }),
    notes: attr('string'),
    feedbackReason: attr('string'),
    feedbackNote: attr('string'),
    feedbackNps: attr('number'),
    feedbackAt: attr('date'),
    utmSource: attr('string'),
    utmMedium: attr('string'),
    utmCampaign: attr('string'),
    utmTerm: attr('string'),
    utmContent: attr('string'),
    shop: belongsTo('shop'),
    location: belongsTo('location'),
    customer: belongsTo('customer'),
    shippingAddress: belongsTo('address'),
    billingAddress: belongsTo('address'),
    returnAddress: belongsTo('address'),
    prescription: belongsTo('prescription'),
    items: hasMany('item'),
    hasCustomer: bool('customer.id'),
    hasLocation: bool('location.id'),
    shopId: readOnly('shop.id'),
    shippingAddressId: readOnly('shippingAddress.id'),
    hasShippingAddress: bool('shippingAddress.id'),
    hasBillingAddress: bool('billingAddress.id'),
    hasReturnAddress: bool('returnAddress.id'),
    hasPrescription: bool('prescription.id'),
    hasReturnTrackingNumber: bool('returnTrackingNumber'),
    hasProducts: gt('productsCount', 0),
    isFulfilled: bool('fulfilledAt'),
    isCanceled: bool('canceledAt'),
    isPaid: bool('paidAt'),
    isReturned: bool('returnedAt'),
    isFree: lte('total', 0),
    isNotFree: not('isFree'),
    hasDiscount: gt('discountPrice', 0),
    products: mapBy('items', 'product'),
    productIds: mapBy('products', 'id'),
    itemsWithPrescription: filterBy('items', 'needsPrescription'),
    needsPrescription: notEmpty('itemsWithPrescription'),
    itemsWithProgressiveLenses: filterBy('items', 'needsProgressiveLenses'),
    needsProgressiveLenses: notEmpty('itemsWithProgressiveLenses'),
    hasItems: notEmpty('items'),
    itemsCount: readOnly('items.length'),
    htoItems: filterBy('items', 'hto'),
    hasHtoItems: notEmpty('htoItems'),
    virtualGifts: filterBy('items', 'isVirtualGift'),
    hasVirtualGifts: notEmpty('virtualGifts'),
    virtualGiftsCount: readOnly('virtualGifts.length'),
    hasRegularItems: notEmpty('regularItems'),
    htoItemsCount: readOnly('htoItems.length'),
    htoProducts: mapBy('htoItems', 'product'),
    htoProductIds: mapBy('htoProducts', 'id'),
    regularItems: setDiff('items', 'htoItems'),
    regularItemsCount: readOnly('regularItems.length'),
    needsBillingAddress: gt('regularItemsCount', 0),
    hasInvoice: bool('invoiceNumber'),
    hasTrackingNumber: bool('trackingNumber'),
    paymentIsRequired: or('isHto', 'isNotFree'),
    delayedShippingItems: filterBy('items', 'delayedShipping'),
    delayedShipping: notEmpty('delayedShippingItems'),
    isHto: Ember.computed('hasItems', 'htoItemsCount', 'itemsCount', function () {
      return this.hasItems && this.htoItemsCount == this.itemsCount;
    }),
    isVirtual: Ember.computed('hasItems', 'virtualGiftsCount', 'itemsCount', function () {
      return this.hasItems && this.virtualGiftsCount == this.itemsCount;
    }),
    vat: Ember.computed('items.@each.vat', 'discountVat', function () {
      return (this.items || []).reduce(function (sum, item) {
        return sum + item.totalVat;
      }, -this.discountVat);
    }),
    invoiceItems: Ember.computed('regularItems.@each.vatRate', function () {
      return (this.regularItems || []).sortBy('vatRate');
    }),
    maxVatRate: Ember.computed('invoiceItems.lastObject.vatRate', function () {
      return (this.invoiceItems || []).reduce(function (max, item) {
        return item.vatRate > max ? item.vatRate : max;
      }, 0);
    }),
    discountVat: Ember.computed('discountPrice', 'maxVatRate', function () {
      return this.discountPrice - this.discountPrice / (1 + this.maxVatRate / 100);
    }),
    productsCount: Ember.computed('items.@each.quantity', function () {
      return (this.items || []).reduce(function (sum, item) {
        return sum + item.quantity;
      }, 0);
    }),
    hasAutoDiscount: Ember.computed('hasDiscount', 'discountCode', function () {
      return this.hasDiscount && Ember.isEmpty(this.discountCode);
    }),
    invoiceAddress: Ember.computed('hasBillingAddress', 'hasShippingAddress', function () {
      return this.hasBillingAddress ? this.billingAddress : this.shippingAddress;
    }),
    invoiceNumberWithPrefix: Ember.computed('shop.countryCode', 'invoiceNumber', function () {
      return this.get('shop.countryCode') + (0, _numeral.default)(this.invoiceNumber || 0).format('000000');
    }),
    canApplyDiscount: Ember.computed('hasVirtualGifts', 'isHto', function () {
      return !this.hasVirtualGifts && !this.isHto;
    }),
    trackingUrl: Ember.computed('trackingNumber', function () {
      var url = 'https://www.tnt.com/express/ro_ro/site/shipping-tools/tracking.html';

      if (this.trackingNumber) {
        return "".concat(url, "?searchType=con&cons=").concat(this.trackingNumber);
      }
    }),
    isReadyForPickup: Ember.computed('isFulfilled', 'hasLocation', 'hasShippingAddress', function () {
      return this.isFulfilled && this.hasLocation && !this.hasShippingAddress;
    }),
    clearCardAttributes: function clearCardAttributes() {
      var _this = this;

      this.eachAttribute(function (attr) {
        if (/^card/.test(attr)) {
          _this.set(attr, '');
        }
      });
    }
  });

  _exports.default = _default;
});